import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from '@/i18n'
import moment from 'moment-timezone';
import jQueryui from '~/assets/scripts/jquery-ui-1.13.0/jquery-ui.min.js'
import { ProductTypes, SuiteRoles } from '@/utils/constants'
import mitt from 'mitt'
import '@/assets/commonCss.scss'
import '~/assets/fonts/open-sans/style.css';

 $.extend(jQueryui);

 const emitter = mitt()

const app = createApp( App )
  .use( router )
  .use( store )
  .use( i18n )
  .use( VueCookieNext )
  .provide('emitter', emitter)
router.isReady( ).then( ( ) => { app.mount( '#app' ) } )

app.config.globalProperties.$filters = {
  formatSuiteRole(value: String){
    let name = ''
    switch(value){
      case SuiteRoles.SUPERADMIN:
        name = i18n.global.t('suite_role_super_admin')
        break;
      case SuiteRoles.ADMIN:
        name = i18n.global.t('suite_role_admin')
        break;
      case SuiteRoles.OBSERVER:
        name = i18n.global.t('suite_role_observer')
        break;
    }
    return name
  },
  formatCheckpointType(value: String) {
    let name = ''
    switch(value){
      case 'CLICK':
        name = i18n.global.t('suite_checkpoint_event_click')
        break;
      case 'CHANGE':
        name = i18n.global.t('suite_checkpoint_event_change')
        break;
      case 'URL_LOAD':
        name = i18n.global.t('suite_checkpoint_event_url_load')
        break;
      case 'SCROLL':
        name = i18n.global.t('suite_checkpoint_event_scroll')
        break;
      case 'VIEW':
        name = i18n.global.t('suite_checkpoint_event_html')
        break;
    }
    return name
  },
  formatStudyStatus(value: String) {
    let status = '-'
    switch(value){
      case 'CONSTRUCTION':
        status = i18n.global.t('suite_dashboard_status_construction')
        break;
      case 'CONFIRMED':
        status = i18n.global.t('suite_dashboard_status_confirmed2')
        break;
      case 'DRAFT':
        status = i18n.global.t('suite_dashboard_status_draft')
        break;
      case 'IN_PROGRESS':
        status = i18n.global.t('suite_dashboard_status_progress2')
        break;
      case 'FINISHED':
        status = i18n.global.t('Finalizado')
        break;
    }
    return status
  },
  formatProductSubType(value: string){
    let type = '-'
    switch(value){
      case ProductTypes.WAT_UX_QUANTI:
        type = i18n.global.t('suite_quanti')
        break;
      case ProductTypes.WAT_UX:
        type = i18n.global.t('suite_ux')
        break;
    }
    return type
  },
  formatProductType(value: String) {
    let type = '-'
    switch(value){
      case ProductTypes.WAT_SURVEY:
        type = i18n.global.t('suite_product_wat_survey')
        break;
      case ProductTypes.WAT_LAB:
        type = i18n.global.t('suite_product_wat_lab')
        break;
      case ProductTypes.WAT_CORPORATE:
        type = i18n.global.t('suite_product_wat_corporate')
        break;
      case ProductTypes.WAT_UX:
        type = i18n.global.t('suite_product_wat_ux')
        break;
      case ProductTypes.WAT_UX_QUANTI:
        type = i18n.global.t('suite_product_wat_ux_quanti')
        break;
      case ProductTypes.WAT_FOCUS:
        type = i18n.global.t('suite_product_wat_focus')
        break;
      case ProductTypes.EXPERIENCE_LAB:
        type = i18n.global.t('suite_product_el')
        break;
      case ProductTypes.CLICK_TEST:
        type = i18n.global.t('suite_product_click_test')
        break;
      case 'CREDITS_PLAN':
        type = i18n.global.t('suite_product_credits')
        break;
      case 'OTROS':
        type = i18n.global.t('suite_product_other')
        break;
    }
    return type
  },
  getPlanName(name: any){
    switch (name) {
      case 'GOLD':
        return i18n.global.t('suite_plan_gold')
        break;
      case 'SILVER':
        return i18n.global.t('suite_plan_silver')
        break;
      case 'BRONZE':
        return i18n.global.t('suite_plan_bronze')
        break;
    }
  },
  formatDate(date:Date, format:string){
    moment.locale('es');
    if(date == undefined) return "-"
    return moment(date).format(format);
  },
  formatChartMedia(media:any){
    let data = []
    for(let m of media){
      if(m.length > 0)data.push({src:m})
    }
    return data
  }
}

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
  $t: typeof i18n;
  }
}

export default app;
