import ApiService from '@/service'


  const actions = {
    async fetchUsers(context: any, payload:any) {
        const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
        .get(`/clientSuite/suitePlan/${suitePlanId}/users`)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async fetchUser(context: any, payload:any) {
        const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
        .get(`/clientSuite/suitePlan/${suitePlanId}/users/${payload.userId}`)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async createUser(context: any, payload:any) {
        const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post(`/clientSuite/suitePlan/${suitePlanId}/users`, payload)
      .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async updateUser(context: any, payload:any) {
        const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .put(`/clientSuite/suitePlan/${suitePlanId}/users/${payload.userId}`, payload)
      .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async deleteUser(context: any, payload:any) {
        const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .delete(`/clientSuite/suitePlan/${suitePlanId}/users/${payload.userId}`)
      .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
  }

export default{
   actions
}
