import ApiService from '~/services/api.service'
import store from '@/store'
import app from '@/main'

ApiService.setConfig( {
  baseURL: process.env.VUE_APP_API_URL,
  token: () => { return store.getters.authToken },
  testerVisitorToken: () => { return store.getters.testerVisitorToken },
  interceptors: [
    {
      type: 'request',
      success_cb: (config: any) => config,
      error_cb: (error: any) => Promise.reject( error )
    },
    {
      type: 'response',
      success_cb: (response: any)  => {
        return response;
      },
      error_cb:   (error: any)     => {
        if(error.response.status == 401){
          store.dispatch('closeSession')
          app.config.globalProperties.$router.push('/')
        }
        return Promise.reject(error);
      },
    }
  ]
} );



export default ApiService;
