import { createI18n } from 'vue-i18n/index';
import { merge } from 'lodash';

import esMessagesGeographic from '~/i18n/geographic/es.json';
import esMessagesWatMonitor from '~/i18n/watmonitor/es.json';
import esMessagesExecution from '~/i18n/execution/es.json';
import esMessagesCommunities from '~/i18n/communities/es-ES.json';
import esMessagesSuite from '~/i18n/suite/es.json';


import itMessagesExecution from '~/i18n/execution/it.json';
import enMessagesExecution from '~/i18n/execution/en.json';
import euMessagesExecution from '~/i18n/execution/eu.json';
import deMessagesExecution from '~/i18n/execution/de.json';
import frMessagesExecution from '~/i18n/execution/fr.json';

/*Portugal*/
import ptMessagesSuite from '~/i18n/suite/pt.json';
import ptMessagesGeographic from '~/i18n/geographic/pt.json';
import ptMessagesWatmonitor from '~/i18n/watmonitor/pt.json';
import ptMessagesExecution from '~/i18n/execution/pt.json';

/* Engish */
import enMessagesSuite from "~/i18n/suite/en.json";
import enMessagesWatMonitor from "~/i18n/watmonitor/en.json";



const messages = {
	es: merge(esMessagesGeographic, esMessagesWatMonitor, esMessagesExecution, esMessagesCommunities, esMessagesSuite),
	it: merge(itMessagesExecution),
	en: merge(enMessagesExecution, enMessagesSuite, enMessagesWatMonitor),
	de: merge(deMessagesExecution),
	fr: merge(frMessagesExecution),
	pt: merge(ptMessagesSuite, ptMessagesGeographic, ptMessagesWatmonitor, ptMessagesExecution),
	eu: merge(euMessagesExecution),
};

export default createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'es',
  messages: messages
});
