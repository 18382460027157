import ApiService from '@/service'
import Swal from 'sweetalert2'
import { SuiteRoles } from '@/utils/constants'
import i18n from '@/i18n'

  const state = {
    studies:[],
    allCreditsPlan:[],
    creditsPlan:{},
    clientLanguage: 'es',
    clientRole:'',
    transactions:[],
    canCreateStudy: false,
    productsEnabled:[],
    notifications:{
      alerts:[],
      popUp:[],
      unseenAlerts:0
    }
  }

  const getters = {
    clientStudies(state: any){
      return state.studies
    },
    clientCreditsPlan(state: any){
      return state.creditsPlan
    },
    canCreateStudy(state: any){
      return state.canCreateStudy
    },
    clientTransactions(state: any){
      return state.transactions
    },
    notificationsPopUp(state: any){
      return state.notifications.popUp
    },
    notificationsAlert(state: any){
      return state.notifications.alerts
    },
    notificationsUnseen(state: any){
      return state.notifications.unseenAlerts
    },
    isSuperAdmin(state: any){
      return state.clientRole == SuiteRoles.SUPERADMIN
    },
    isAdmin(state: any){
      return state.clientRole == SuiteRoles.ADMIN
    },
    isObserver(state: any){
      return state.clientRole == SuiteRoles.OBSERVER
    },
    clientCountry(state: any){
      return state.clientLanguage
    },
    productsEnabled(state: any){
      return state.productsEnabled
    }
  }

  const actions = {
    async getClientStudies(context: any, payload:any) {
      return ApiService
        .get('/clientSuite/clientArea/studies', payload)
        .then(({data}: any) => {
          context.commit('setStateClientStudies',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getClientStudy(context: any, payload:any) {
      return ApiService
        .get('/clientSuite/clientArea/studies/' + payload.studyId, null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getCreditsPlan(context: any, payload: any){
      return ApiService
        .get('/clientSuite/creditsPlan/planData', null)
        .then(({data}: any) => {
          context.commit('setStateCreditsPlan',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getClientTransactions(context:any, payload: any){
      return ApiService
        .get('/clientSuite/creditsPlan/transactions', payload)
        .then(({data}: any) => {
          context.commit('setStateTransactions',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async contactForCreditsPlan(context: any, payload: any){
      return ApiService
        .post('/clientSuite/creditsPlan/contactForm', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async contactForDiy(context: any, payload: any){
      return ApiService
        .post('/clientSuite/diy/contactForm', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async getNotifications(context: any, payload: any){
      return ApiService
        .get('/clientSuite/clientNotifications', payload)
        .then(({data}: any) => {
          context.commit('setStateNotifications',data)
          if(data.popup) context.dispatch('notifyPopUp', data.popup)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async seeNotification(context: any, payload: any){
      return ApiService
        .post('/clientSuite/clientNotifications/seen', payload)
        .then(({data}: any) => {
          context.dispatch('getNotifications')
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async uploadFile(context: any, payload: any){
      var formData = new FormData();
      formData.append("file", payload);
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      return ApiService
        .postFile('/file', formData, headers)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async duplicateStudy(context: any, payload: any){
      return ApiService
        .post('/clientSuite/clientArea/studies/'+payload.studyId+'/duplicate', {title: payload.title})
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async deleteStudy(context: any, payload: any){
      return ApiService
        .delete('/clientSuite/clientArea/studies/'+payload.studyId)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async updateClientLanguage(context: any, payload: any){
      return ApiService
      .put('/clientSuite/clientLanguage', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return response;
      })
    },
    notifyPopUp(context: any, notification: any){
      for(let n of notification){
        if(!n.seen) {
          Swal.fire({
            title: n.modalTitle.translated,
            icon: 'info',
            html: n.modalHtml.translated,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Aceptar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonAriaLabel: 'Thumbs down'
          }).then((result) => {
            context.dispatch('seeNotification',{idsToSee:[n.id]})
          })
        }
      }
    },
  }

  const mutations = {
    setStateClientStudies(state: any,data: any){
      state.studies = data;
    },
    setStateCreditsPlan(state: any,data: any){
      i18n.global.locale = data.language
      state.allCreditsPlan = data.plansData;
      state.clientRole = data.highestRole;
      state.clientLanguage = data.language;
      state.canCreateStudy = data.canCreateStudy
      state.productsEnabled = data.productsEnabled
      for(let planData of data.plansData){
        if(planData.suiteClientRole.role == data.highestRole) state.creditsPlan = planData.suitePlan
      }
    },
    setStateTransactions(state: any,data: any){
      state.transactions = data;
    },
    setStateNotifications(state: any,data: any){
      state.notifications = data;
    },
  }

export default{
  state,
  getters,
  actions,
  mutations
}
