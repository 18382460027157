import ApiService from '@/service'

  const actions = {
    async createStatisticsProject(context: any, payload:any) {
      return ApiService
      .post('/statistics/projectRelated', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async createStatisticsClient(context: any, payload:any) {
      return ApiService
      .post('/statistics/clientRelated', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
  }


export default{
  actions
}
